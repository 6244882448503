<template>
  <!--
    1. Farmer has been registered outside your domain/work area, there your cannot edit his.heer record
    2. Farmer has beed red=gsitered and his record is editedable . click continue to edit
    3. Farmer has not record. To registere this farmer click continue
  -->
  <Layout is-demo>
    <div class="">
      <h1 class="text-h4">Farmer's Registration</h1>
      <div class="caption green--text">LGA: {{ lga }}</div>
      <v-divider class="my-2"></v-divider>
    </div>
    <v-alert
      outlined
      type="success"
      icon="mdi-alert"
      text
      prominent
      close-text="Close Alert"
      dismissible
    >
      Bank Verification Number(BVN) is not mandatory but might stop the farmer enjoy certain benefits</v-alert>
    <v-form ref="form" v-model="valid" fast-fail>
      <v-card class="mb-3">
        <v-card-title>Farmers details</v-card-title>
        <v-card-text>
          <v-row dense>
            <v-col cols="12" sm="12" md="4">
              <v-text-field
                v-model="phone"
                :rules="phoneRules"
                label="Phone Number*"
                :counter="11"
                type="number"
                required
              ></v-text-field>
              <!--<v-text-field
                v-model="phone"
                :rules="phoneRules"
                :counter="11"
                type="number"
                label="Phone Number *"
                maxlength="11"
                required
              ></v-text-field>-->
            </v-col>
            <v-col cols="12" sm="12" md="4" class="text-center">
              <v-btn block color="primary" large @click="verifyFarmer">
                Verify Farmer
              </v-btn>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
      <v-card class="mb-3">
        <v-card-title>Farmer's Bios</v-card-title>
        <v-card-text>
          <v-row dense>
            <v-col cols="12" sm="12" md="6">
              <v-text-field
                v-model="first_name"
                label="Farmer First Name*"
                :rules="namesRules"
                required
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="12" md="6">
              <v-text-field
                v-model="surname"
                label="Farmer Surname*"
                :rules="namesRules"
                required
              ></v-text-field>
            </v-col>
            <v-col class="d-flex" cols="12" sm="12" md="4">
              <v-select
                v-model="gender"
                :items="gender_arr"
                return-object
                label="Gender*"
                item-text="title"
                item-value="id"
                :rules="[(v) => !!v || 'Item is required']"
              ></v-select>
            </v-col>
            <v-col cols sm="12" md="4">
              <v-select
                v-model="educational_background_rf"
                return-object
                item-text="title"
                item-value="id"
                :items="educational_background_arr"
                :rules="[(v) => !!v || 'Item is required']"
                label="Educational Background*"
                required
              ></v-select>
            </v-col>
            <v-col cols="12" sm="12s" md="4">
              <v-menu
                ref="menu"
                v-model="menu"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="dob"
                    label="Date Of Birth*"
                    prepend-icon="mdi-calendar"
                    readonly
                    :rules="dateRules"
                    v-bind="attrs"
                    required
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="dob"
                  :active-picker.sync="activePicker"
                  :max="(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substring(0, 10)"
                  min="1950-01-01"
                  @change="save"
                ></v-date-picker>
              </v-menu>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
      <v-card class="mb-3">
        <v-card-title>Farmer's Residency Details</v-card-title>
        <v-card-text>
          <v-row dense>
            <v-col cols sm="12" md="6">
              <v-select
                v-model="ward_rf"
                return-object
                item-text="title"
                item-value="id"
                :items="ward_arr"
                :rules="[(v) => !!v || 'Item is required']"
                label="Residence Ward*"
                required
              ></v-select>
            </v-col>
            <v-col cols="12" sm="12" md="6">
              <v-text-field
                v-model="village"
                label="village*"
                :rules="namesRules"
                required
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="12">
              <v-text-field
                v-model="home_address"
                :rules="addressRules"
                label="Home Address*"
                clearable
              ></v-text-field>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
      <v-card class="mb-3">
        <v-card-title>Farm Information</v-card-title>
        <v-card-text>
          <v-row dense>
            <v-col cols sm="12" md="6">
              <v-select
                v-model="commodity_rf"
                return-object
                item-text="title"
                item-value="id"
                :items="commodity_arr"
                :rules="[(v) => !!v || 'Item is required']"
                label="Commodity*"
                required
              ></v-select>
            </v-col>
            <v-col cols="12" sm="6" md="6">
              <v-text-field
                v-model="farm_size_ha"
                label="Farm Size(HA)*"
                required
                type="number"
                :rules="numberRules"
              ></v-text-field>
            </v-col>
            <v-col cols sm="12" md="6">
              <v-select
                v-model="commodity_stakeholder_id"
                return-object
                item-text="title"
                item-value="id"
                :items="commodity_stakeholder_arr"
                label="Commodity Stakeholder"
                required
              ></v-select>
            </v-col>
            <v-col cols sm="12" md="6">
              <v-select
                v-model="group_lead_id"
                return-object
                item-text="title"
                item-value="id"
                :items="group_lead_arr"
                :rules="[(v) => !!v || 'Item is required']"
                label="Group Lead*"
                required
              ></v-select>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
      <v-card class="mb-3">
        <v-card-title>National Identity</v-card-title>
        <v-card-text>
          <v-row dense>
            <v-col cols sm="12" md="6">
              <v-select
                v-model="identity_type_rf"
                return-object
                item-text="title"
                item-value="id"
                :items="identity_type_arr"
                :rules="[(v) => !!v || 'Item is required']"
                label="Identity Card Type*"
                required
              ></v-select>
            </v-col>
            <v-col cols="12" sm="6" md="6">
              <v-text-field
                v-model="identity_number"
                label="Identity Card Number*"
                required
                :rules="namesRules"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="6">
              <v-text-field
                v-model="bvn"
                label="Bank Verification Number(BVN)"
                :rules="bvnRules"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
      <v-card class="mb-3">
        <v-card-title>Farmer's Additional Contact Detail</v-card-title>
        <v-card-text>
          <v-row dense>
            <v-col cols="12" sm="12" md="6">
              <v-text-field
                v-model="alternate_phone"
                label="Alternate Phone"
                :rules="phoneAltRules"
                maxlength="11"
                :counter="11"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="12" md="6">
              <v-text-field
                v-model="whatsapp_phone"
                label="Whatsapp Phone"
                :rules="phoneAltRules"
                maxlength="11"
                :counter="11"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
      <v-row justify="center" align="center" dense>
        <v-col cols="12" sm="12" md="6" class="text-center">
          <v-btn
            rounded
            block
            color="primary"
            large
            @click="showDialog()"
          >
            Submit
          </v-btn>
        </v-col>
      </v-row>
    </v-form>
    <v-snackbar v-model="snackbar" right>
      {{ snackbartext }}
      <template v-slot:action="{ attrs }">
        <v-btn color="blue" text v-bind="attrs" @click="snackbar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
    <v-dialog v-model="dialog" max-width="600px">
      <v-card>
        <v-toolbar color="green" dark dense flat>
          <v-toolbar-title class="text-h5"> Save Farmer </v-toolbar-title>
        </v-toolbar>
        <v-card-subtitle
          class="mt-3"
        >Are sure you want to save this record?</v-card-subtitle>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green darken-1" text @click="close"> Close </v-btn>
          <v-btn color="green darken-1" text outlined @click="postData()">
            Save Data
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </Layout>
</template>

<script>
import Layout from '@/infdb_components/layouts/MainPortalLayout'
import httpServ from '../services/HTTPService'

export default {
  components: {
    Layout
  },
  data() {
    return {
      memberData: {},
      archiveData: {},
      currentLOBDetails: {},
      prePostData: {},
      pageData: {},
      snackbar: false,
      snackbartext: '',
      postStatus: 'new',
      category: 'New Farmer Registration!',
      containsArchive: false,
      activePicker: null,

      isNotLoaded: true,
      absolute: true,
      opacity: 1,
      overlay: false,
      dense: false,
      fixedHeader: false,
      height: 300,
      loading: false,
      loadingGPS: false,
      dialog: false,
      menu: false,

      phone: '',
      state: '',
      lga: '',

      first_name: '',
      surname: '',
      gender: '',

      ward_rf: '',
      educational_background_rf: '',
      home_address: '',
      village: '',

      dob: null,
      whatsapp_phone: '',
      alternate_phone: '',
      farm_size_ha: '',

      gender_arr: ['F', 'M'],
      ward_arr: [],
      commodity_arr: [],
      commodity_rf: '',
      educational_background_arr: [],
      commodity_stakeholder_id: '',
      commodity_stakeholder_arr: [],
      group_lead_id: '',
      group_lead_arr: [],

      identity_type_rf: '',
      identity_type_arr: [],
      bvn: '',
      identity_number: '',
      valid: '',

      formItems: [],
      formId: 0,
      stateItems: [],
      stateItemsId: 0,

      items: [],

      cooperativeNameRules: [(v) => !!v || 'Corperative name is required'],
      namesRules: [
        (v) => !!v || 'Name is required',
        (v) => (v && v.length >= 2) || 'Mame must be greater than 2 characters'
      ],
      dateRules: [
        (v) => !!v || 'Date is required',
        (v) => /^(\d{1,2}\/\d{1,2}\/\d{4,}|\d{4,}-\d{1,2}-\d{1,2})$/.test(v) || 'Date must be valid'
      ],
      phoneRules: [
        (v) => !!v || 'Phone Number is required',
        (v) => /^\d{11}$/.test(v) || 'Phone number be must be valid',
        (v) => (v && v.length <= 11) || 'Phone Number must be 11 digits'
      ],
      phoneAltRules: [
        //(v) => !v || 'Phone Number is required',
        //(v) => /^\d{11}$/.test(v) || 'Phone number be must be valid',
        (v) => (v || '').length <= 11 || 'Phone Number must be 11 charaters'
      ],
      bvnRules: [
        (v) => (v || '').length <= 11 || 'BVN must be 11 charaters'
      ],
      emailRules: [
        (v) => !!v || 'E-mail is required',
        (v) => /.+@.+\..+/.test(v) || 'E-mail must be valid'
      ],
      addressRules: [
        (v) => !!v || 'Name is required',
        (v) =>
          (v && v.length >= 10) || 'Mame must be greater than 9 characters'
      ],
      numberRules: [
        (v) => !!v || 'Tonnage is required',
        (v) => !isNaN(parseFloat(v)) || 'Farm Size must be a number',
        (v) => (v && v > 0) || 'Farm size must greater than zero'
      ],
      coordinateRules: [
        (v) => !!v || 'This coordinates is required',
        (v) => !isNaN(parseFloat(v)) || 'This coordinates must be a number',
        (v) => (v && v > 0) || 'This coordinates must greater than zero'
      ]
    }
  },
  watch: {
    menu (val) {
      val && setTimeout(() => (this.activePicker = 'YEAR'))
    }
  },
  created() {
    this.userData = httpServ.getSessionJson('userData')
    //this.farmerData = httpServ.getSessionJson('farmerData')
    //this.phone = this.farmerData.phone
    //this.stakeholder = this.userData.stakeholder

    //console.log(this.userData)
    this.lga = this.userData.lga
    this.getFormOption()
    this.getFormOptionFarmer()
  },
  mounted() {},
  methods: {
    save (date) {
      this.$refs.menu.save(date)
    },
    close() {
      this.dialog = false
    },
    showDialog: function () {
      this.dialog = true
    },
    verifyFarmer: function () {
      const formUrl = httpServ.getFarmerUrl('/b-aut/dataclerk/farmer/verify')
      const postData = {
        lga_rf: this.userData.lga_rf,
        phone: this.phone
      }

      if (this.phone.length === 11) {
        const resValue = httpServ.postFetch(formUrl, postData)

        resValue.then((result) => {
          //console.log('verify data ', result)
          if (result.status === 200) {
            this.snackbartext = 'This record exist, you cannot continue!' //result.message
            this.snackbar = true
          } else {
            //alert(result.message)
            this.snackbartext = 'This is new record. Please continue' //result.message
            this.snackbar = true
          }
        })
      } else {
        this.snackbartext = 'This phone number is not complete' //result.message
        this.snackbar = true
      }
    },//
    getFormOption: function () {
      //console.log(this.farmerData)
      const formUrl = httpServ.getCacheUrl('/b-aut/dataclerk/registration')
      const resValue = httpServ.makeRequest(formUrl, 'GET', {
        lga_rf: this.userData.lga_rf,
        state_rf: this.userData.state_rf
      })

      resValue.then((result) => {
        if (result.status === 200) {
          this.ward_arr = result.data.wards
          this.educational_background_arr = result.data.educationals
          this.commodity_arr = result.data.commodities
          this.commodity_stakeholder_arr = result.data.associations
          this.identity_type_arr = result.data.identifications
        } else {
          //alert(result.message)
          //this.snackbartext = result.message
          //this.snackbar = true
        }
      })
    },
    getFormOptionFarmer: function () {
      //console.log(this.farmerData)
      const formUrl = httpServ.getCacheUrl('/b-aut/dataclerk/registration/groupLead')
      const resValue = httpServ.makeRequest(formUrl, 'GET', {
        lga_rf: this.userData.lga_rf,
        state_rf: this.userData.state_rf
      })

      resValue.then((result) => {
        if (result.status === 200) {
          //console.log(result.data.wards)
          this.group_lead_arr = result.data.groupLead
        } else {
          //alert(result.message)
          this.snackbartext = result.message
          this.snackbar = true
        }
      })
    },
    getNewData: function () {
      const data = {}

      data.clerk_id = this.userData.clerk_id
      data.first_name = this.first_name
      data.surname = this.surname
      data.phone = this.phone
      data.gender = this.gender
      data.state_rf = this.userData.state_rf
      data.lga_rf = this.userData.lga_rf
      data.ward_rf = this.ward_rf.id
      data.home_address = this.home_address
      data.village = this.village
      data.alternate_phone = this.alternate_phone
      data.whatsapp_phone = this.whatsapp_phone
      data.educational_background_rf = parseInt(
        this.educational_background_rf.id
      )
      data.group_lead_id = this.group_lead_id.id
      data.dob = this.dob
      data.farm_size_ha = this.farm_size_ha
      data.commodity = this.commodity_rf.title
      data.commodity_stakeholder_id = this.commodity_stakeholder_id.id
      data.bvn = this.bvn
      data.identity_type_rf = this.identity_type_rf.id
      data.identity_number = this.identity_number

      return data
    },
    getUpdatedData: function () {
      const data = {}

      return data
    },
    postData: function () {
      this.close()
      const postData =
        this.postStatus === 'new' ? this.getNewData() : this.getUpdatedData()

      //console.log(postData)

      if (this.$refs.form.validate()) {
        const url = httpServ.getFarmerUrl('/b-aut/dataclerk/farmer/save')
        const resp = httpServ.postFetch(url, postData)

        resp.then((result) => {
          if (result.status === 200) {
            httpServ.setSessionJson('farmerData', result.data)
            //console.log(result.data)
            this.snackbartext = 'Your submission was successful'
            this.snackbar = true
            this.$refs.form.reset()
          } else if (result.status === 422) {
            if (result instanceof SyntaxError) {
              // Unexpected token < in JSON
              //console.log('There was a SyntaxError', result)//checker
              this.snackbartext = result.message
              this.snackbar = true
            } else {
              //console.log('There was an error', result) //Checker
              this.snackbartext = result.message
              this.snackbar = true
            }
            this.formBtnLoader = false
          } else {
            this.snackbartext = result.message
            this.snackbar = true
          }
        })
      }
    }
  }
}
</script>

<style scoped></style>
