<template>
  <Layout is-demo>
    <div class="">
      <h1 class="text-h4">Farmer's Registration</h1>
      <div class="caption green--text">{{ category }}</div>
      <v-divider class="my-2"></v-divider>
    </div>
    <v-form ref="form" v-model="valid" fast-fail>
      <v-card class="mb-3">
        <v-card-title>Farmers' verified details</v-card-title>
        <v-card-text>
          <v-row dense>
            <v-col cols="12" sm="12" md="4">
              <v-text-field
                v-model="phone"
                label="Phone"
                readonly
                disabled
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="12" md="4">
              <v-text-field
                v-model="state"
                label="State"
                readonly
                disabled
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="12" md="4">
              <v-text-field
                v-model="lga"
                label="LGA"
                readonly
                disabled
              ></v-text-field>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
      <v-card class="mb-3">
        <v-card-title>Farmer's Bios</v-card-title>
        <v-card-text>
          <v-row dense>
            <v-col cols="12" sm="12" md="6">
              <v-text-field
                v-model="first_name"
                label="Farmer First Name*"
                :rules="namesRules"
                required
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="12" md="6">
              <v-text-field
                v-model="surname"
                label="Farmer Surname*"
                :rules="namesRules"
                required
              ></v-text-field>
            </v-col>
            <v-col class="d-flex" cols="12" sm="12" md="4">
              <v-select
                v-model="gender"
                :items="gender_arr"
                return-object
                label="Gender*"
                item-text="title"
                item-value="id"
                :rules="[(v) => !!v || 'Item is required']"
              ></v-select>
            </v-col>
            <v-col cols sm="12" md="4">
              <v-select
                v-model="educational_background_rf"
                return-object
                item-text="title"
                item-value="id"
                :items="educational_background_arr"
                :rules="[(v) => !!v || 'Item is required']"
                label="Educational Background*"
                required
              ></v-select>
            </v-col>
            <v-col
              cols="12"
              sm="12s"
              md="4"
            >
              <v-menu
                ref="menu"
                v-model="menu"
                :close-on-content-click="false"
                :return-value.sync="dob"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="dob"
                    label="Date Of Birth"
                    prepend-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="dob"
                  no-title
                  scrollable
                >
                  <v-spacer></v-spacer>
                  <v-btn
                    text
                    color="primary"
                    @click="menu = false"
                  >
                    Cancel
                  </v-btn>
                  <v-btn
                    text
                    color="primary"
                    @click="$refs.menu.save(dob)"
                  >
                    OK
                  </v-btn>
                </v-date-picker>
              </v-menu>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
      <v-card class="mb-3">
        <v-card-title>Farmer's Residency Details</v-card-title>
        <v-card-text>
          <v-row dense>
            
            <v-col cols sm="12" md="6">
              <v-select
                v-model="ward_rf"
                return-object
                item-text="title"
                item-value="id"
                :items="ward_arr"
                :rules="[(v) => !!v || 'Item is required']"
                label="Residence Ward*"
                required
              ></v-select>
            </v-col>
            <v-col cols="12" sm="12" md="6">
              <v-text-field
                v-model="village"
                label="village*"
                :rules="namesRules"
                required
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="12">
              <v-text-field
                v-model="home_address"
                :rules="addressRules"
                label="Home Address*"
                clearable
              ></v-text-field>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
      <v-card class="mb-3">
        <v-card-title>Farmer's Additional Contact Detail</v-card-title>
        <v-card-text>
          <v-row dense>
            <v-col cols="12" sm="12" md="6">
              <v-text-field
                v-model="alternate_phone"
                label="Alternate Phone"
                :rules="phoneAltRules"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="12" md="6">
              <v-text-field
                v-model="whatsapp_phone"
                label="Whatsapp Phone"
                :rules="phoneAltRules"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
      <v-row justify="center" align="center" dense>
        <v-col cols="12" sm="12" md="6" class="text-center">
          <v-btn
            rounded
            block
            color="primary"
            large
            @click="showDialog()"
          >
            Submit 
          </v-btn>
        </v-col>
      </v-row>
    </v-form>
    <v-snackbar v-model="snackbar" right>
      {{ snackbartext }}
      <template v-slot:action="{ attrs }">
        <v-btn color="blue" text v-bind="attrs" @click="snackbar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
    <v-dialog v-model="dialog" max-width="600px">
      <v-card>
        <v-toolbar color="green" dark dense flat>
          <v-toolbar-title class="text-h5">
            Save Famer
          </v-toolbar-title>
        </v-toolbar>
        <v-card-subtitle class="mt-3">Are sure you want to save this record?</v-card-subtitle>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green darken-1" text @click="close">
            Close
          </v-btn>
          <v-btn
            color="green darken-1"
            text
            outlined
            @click="postData()"
          >
            Save Data
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </Layout>
</template>

<script>
import Layout from '@/infdb_components/layouts/MainPortalLayout'
import httpServ from '../services/HTTPService'

export default {
  components: {
    Layout
  },
  data() {
    return {
      memberData: {},
      archiveData:{},
      currentLOBDetails: {},
      prePostData: {},
      pageData: {},
      snackbar: false,
      snackbartext:'',
      postStatus: 'new',
      category: 'Farmer Registration Update!',
      containsArchive:false,

      isNotLoaded: true,
      absolute: true,
      opacity: 1,
      overlay: false,
      dense: false,
      fixedHeader: false,
      height: 300,
      loading: false,
      loadingGPS: false,
      dialog:false,
      menu:'',
    
      phone:'',
      state:'',
      lga:'',

      first_name:'',
      surname:'',
      gender:'',
      
      ward_rf:'',
      educational_background_rf:'',
      home_address:'',
      village:'',

      //dob:(new Date(Date.now())).toISOString().substr(0, 10),
      dob:'',
      min_date: this.getMinDate(),
      whatsapp_phone:'',
      alternate_phone:'',
      farmer_id:'',

      gender_arr: ['F','M'],  
      ward_arr: [],  
      educational_background_arr:[],   
      valid: '',

      formItems: [],
      formId: 0,
      stateItems: [],
      stateItemsId: 0,
    
      items: [],

      cooperativeNameRules: [
        (v) => !!v || 'Corperative name is required'
      ],
      namesRules: [
        (v) => !!v || 'Name is required',
        (v) =>
          (v && v.length >= 2) ||
        'Mame must be greater than 2 characters'
      ],
      phoneRules: [
        //(v) => !!v || 'Phone Number is required',
        //(v) => /^\d{11}$/.test(v) || 'Phone number be must be valid',
        //(v) => (v && v.length <= 11) || 'Phone Number must be 11 figures'
      ],
      phoneAltRules: [
        //(v) => !v || 'Phone Number is required',
        //(v) => /^\d{11}$/.test(v) || 'Phone number be must be valid',
        (v) =>  (v || '' ).length <= 11 || 'Phone Number must be 11 charaters'
      ],
      emailRules: [
        (v) => !!v || 'E-mail is required',
        (v) => /.+@.+\..+/.test(v) || 'E-mail must be valid'
      ],
      addressRules: [
        (v) => !!v || 'Name is required',
        (v) =>
          (v && v.length >= 15) ||
        'Mame must be greater than 15 characters'
      ],
      numberRules: [
        (v) => !!v || 'Tonnage is required',
        (v) => !isNaN(parseFloat(v)) || 'Tonnage must be a number',
        (v) => (v && v > 0) || 'Tonnage must greater than zero'
      ],
      coordinateRules: [
        (v) => !!v || 'This coordinates is required',
        (v) => !isNaN(parseFloat(v)) || 'This coordinates must be a number',
        (v) => (v && v > 0) || 'This coordinates must greater than zero'
      ]
    }
  },
  watch: {
    /*state: {
      handler(newvalue) {
        this.lga_arr = this.all_lga_arr.filter(
          (lga) => lga.state_id === this.state.id
        )
      },
      immediate: true
    },
    lga: {
      handler(newvalue) {
        if (newvalue !== undefined) {
          this.getCooperative(newvalue.title)
        }
      },
      immediate: true
    }*/
  },
  created() {
    this.userData = httpServ.getSessionJson('userData')
    this.farmerData = httpServ.getSessionJson('farmerData')
    //console.log(this.farmerData)
    this.getFormOption()
  },
  mounted() {},
  methods: 
  {
    getMinDate: function() {
      const now = new Date()

      return new Date(now.setFullYear(now.getFullYear() + 17))
    },
    saveDate (date) {
      this.$refs.menu.save(date)
    },
    close() {
      this.dialog = false
    },
    showDialog: function() {
      this.dialog = true
    },
    getGPS: function() {
      this.loadingGPS = true
      const success = (position) => {
        this.latitude  = position.coords.latitude
        this.longitude = position.coords.longitude
        this.loadingGPS = false
      }

      const error = (err) => {
        console.log(err)
        this.loadingGPS = false
      }

      navigator.geolocation.getCurrentPosition(success, error)
    },
    getFormOption: function() {
      const formUrl = httpServ.getNAGSAPUrl('/enumeration/formOptions/newRegistration')
      const resValue = httpServ.makeRequest(formUrl, 'GET', { 
        'state_rf':this.farmerData.state_rf,
        'lga_rf':this.farmerData.lga_rf
      })
      
      resValue.then((result) => {
        //console.log('our select is ', result)
        if (result.status === 200) {
          this.state = result.data.states[0].title
          this.lga = result.data.lgas[0].title
          this.ward_arr = result.data.wards
          this.educational_background_arr = result.data.educational_backgrounds

          this.phone = this.farmerData.phone
          this.alternate_phone = this.farmerData.alternate_phone
          this.whatsapp_phone = this.farmerData.whatsapp_phone   
          this.first_name = this.farmerData.first_name
          this.surname = this.farmerData.surname
          this.village = this.farmerData.village
          this.dob = new Date(this.farmerData.dob).toISOString().substr(0, 10),
          this.home_address = this.farmerData.home_address

          this.ward_rf = this.farmerData.ward_rf
          this.educational_background_rf = this.farmerData.educational_background_rf
          this.gender = this.farmerData.gender
        } else {
          //alert(result.message)
          this.snackbartext = result.message
          this.snackbar = true
        }
      })
    },
    getNewData: function() {
      const data = {}

      data.farmer_id = this.farmerData.farmer_id
      data.user_id = this.userData.user_id
      data.first_name = this.first_name
      data.surname = this.surname
      data.phone = this.phone
      data.gender = this.gender
      data.state_rf = this.farmerData.state_rf
      data.lga_rf = this.farmerData.lga_rf
      data.ward_rf = this.ward_rf
      data.home_address = this.home_address
      data.village = this.village
      data.alternate_phone = this.alternate_phone
      data.whatsapp_phone = this.whatsapp_phone
      data.educational_background_rf = parseInt(this.educational_background_rf)
      data.dob = this.dob

      return data
    },
    getUpdatedData: function() {
      const data = {}
      
      return data
    },
    postData: function() {
      this.close()
      const postData = this.postStatus === 'new' ? this.getNewData() : this.getUpdatedData()
      
      //console.log(postData)

      if (this.$refs.form.validate()) {
        const url = httpServ.getNAGSAPUrl('/enumeration/dataclerk/update')
        const resp = httpServ.postFetch(url,postData)
        
        resp.then((result ) => {
          if (result.status === 200) {
            httpServ.setSessionJson('farmerData',result.data)
            this.snackbartext = 'Your submission was successful'
            this.snackbar = true
            setTimeout(() => {
              this.$router.push({ name: 'commodity' })
            }, 1000)
          }  else if (result.status === 422) {
            if (result instanceof SyntaxError) {
              // Unexpected token < in JSON
              //console.log('There was a SyntaxError', result)//checker
              this.snackbartext = result.message
              this.snackbar = true
            } else {
              //console.log('There was an error', result) //Checker
              this.snackbartext = result.message
              this.snackbar = true

            }
            this.formBtnLoader = false  
          } else {
            this.snackbartext = result.message 
            this.snackbar = true
          }
        })
      }
    }
  }
}
</script>

<style scoped></style>
