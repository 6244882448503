import { render, staticRenderFns } from "./DefaultChangePassword.vue?vue&type=template&id=f7712798&scoped=true&"
import script from "./DefaultChangePassword.vue?vue&type=script&lang=js&"
export * from "./DefaultChangePassword.vue?vue&type=script&lang=js&"
import style0 from "./DefaultChangePassword.vue?vue&type=style&index=0&id=f7712798&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f7712798",
  null
  
)

export default component.exports