<template>
  <Layout is-demo>
    <div class="">
      <h1 class="text-h4">Entry Summaries</h1>
      <v-divider class="my-2"></v-divider>
    </div>
    <v-row class="pa-0 ma-0">
      <v-col sm="12" class="pa-0 ma-0">
        <v-card color="basil">
          <v-tabs 
            fixed-tabs
            show-arrows
            background-color="green lighten-1"
            slider-color="yellow"
            dark
          >
            <v-tab>Farmers</v-tab>
            <v-tab>Group Leads</v-tab>
            <v-tab-item>
              <SumFarmer />
            </v-tab-item>
            <v-tab-item>
              <SumCommodities />
            </v-tab-item>
          </v-tabs>
        </v-card>
      </v-col>
    </v-row>
    <v-snackbar v-model="snackbar" right>
      {{ snackbartext }}
      <template v-slot:action="{ attrs }">
        <v-btn color="blue" text v-bind="attrs" @click="snackbar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </Layout>
</template>

<script>
import Layout from '@/infdb_components/layouts/MainPortalLayout'
//import httpServ from '../services/HTTPService'
import SumFarmer from '@/infdb_components/tab/SummaryFarmer.vue'
import SumCommodities from '@/infdb_components/tab/SummaryCommodities.vue'
import moment from 'moment'

export default {
  components: {
    Layout,
    SumFarmer,
    SumCommodities
  },
  data() {
    return {
      memberData:{},
      currentLOBDetails:{},
      prePostData:{},
      pageData:{},
      snackbar: false,
      snackbartext:''
    }
  },
  created() {
    //this.memberData = httpServ.getSessionJson('memberData')
  },
  mounted() {},
  methods: {}
}
</script>

<style scoped>
</style>
