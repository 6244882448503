<template>
  <div>
    <UpdateRegistration />
  </div>
</template>

<script>
import UpdateRegistration from '@/infdb_components/UpdateRegistration.vue'
export default {
  components: {
    UpdateRegistration
  }
}
</script>