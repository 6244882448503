export default [{
  text: '',
  items: [
    { icon: 'mdi-view-dashboard-outline', key: 'menu.dashboard', text: 'Dashboard', link: '/dashboard'  },
    { icon: 'mdi-account-group', key: 'menu.blank', text: 'Group Lead', link: '/groupLead' } ,
    { icon: 'mdi-nature-people', key: 'menu.blank', text: 'Farmers Registration', link: '/newRegistration' },
    //{ icon: 'mdi-bell', key: 'menu.blank', text: 'Crop', link: '/commodity' } ,
    //{ icon: 'mdi-sprout', key: 'menu.blank', text: 'Farmer Verifier', link: '/registrationVerifier' },
    //{ icon: 'mdi-account-question', key: 'menu.blank', text: 'Update Password', link: '/defaultChangePassword' },
    { icon: 'mdi-chart-bell-curve', key: 'menu.blank', text: 'Summaries', link: '/summary' },
    //{ icon: 'mdi-bell', key: 'menu.blank', text: 'Picture', link: '/picture' } ,
    { icon: 'mdi-logout', key: 'menu.blank', text: 'Logout', link: '/' } 
  ]
}]
