<template>
  <div>
    <GroupLead />
  </div>
</template>

<script>
import GroupLead from '@/infdb_components/GroupLead.vue'
export default {
  components: {
    GroupLead
  }
}
</script>