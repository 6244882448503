<template>
  <div>
    <NewRegistration />
  </div>
</template>

<script>
import NewRegistration from '@/infdb_components/NewRegistration.vue'
export default {
  components: {
    NewRegistration
  }
}
</script>