<template>
  <div>
    <RegistrationVerifier />
  </div>
</template>

<script>
import RegistrationVerifier from '@/infdb_components/RegistrationVerifier.vue'
export default {
  components: {
    RegistrationVerifier
  }
}
</script>