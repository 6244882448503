<template>
  <div>
    <Summary />
  </div>
</template>

<script>
import Summary from '@/infdb_components/Summary.vue'
export default {
  components: {
    Summary
  }
}
</script>