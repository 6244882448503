<template>
  <Layout is-demo>
    <div class="">
      <h1 class="text-h4">Farmers Verifier</h1>
      <v-divider class="my-2"></v-divider>
    </div>
    <div v-if="hasActiveAccount">
      <v-form ref="form" v-model="valid" fast-fail>
        <v-card class="mb-3">
          <v-card-title>Farmers details</v-card-title>
          <v-card-text>
            <v-row dense>
              <v-col cols="12" sm="12" md="4">
                <v-text-field
                  v-model="phone"
                  :rules="phoneRules"
                  :counter="11"
                  label="Phone Number *"
                  maxlength="11"
                  required
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="12" md="4">
                <v-select
                  v-model="state"
                  return-object
                  item-text="title"
                  item-value="id"
                  :items="state_arr"
                  :rules="[(v) => !!v || 'Item is required']"
                  label="Farmer's State *"
                  required
                ></v-select>
              </v-col>
              <v-col cols="12" sm="12" md="4">
                <v-select
                  v-model="lga"
                  return-object
                  item-text="title"
                  item-value="id"
                  :items="lga_arr"
                  :rules="[(v) => !!v || 'Item is required']"
                  label="Farmer's LGA *"
                  required
                ></v-select>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
        <v-row justify="center" align="center">
          <v-col cols="12" sm="12" md="6" class="text-center">
            <v-btn
              rounded
              block
              color="primary"
              large
              @click="verify"
            >
              Submit 
            </v-btn>
          </v-col>
        </v-row>
      </v-form>
      <!--<div>afrm center <br/>Phone dial<br> share phone wheah only <br> camera adjustment,<br>Helpline staff Pinisment</div>-->
    </div>
    <v-snackbar v-model="snackbar" right>
      {{ snackbartext }}
      <template v-slot:action="{ attrs }">
        <v-btn color="blue" text v-bind="attrs" @click="snackbar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
    <v-dialog v-model="dialog1" max-width="600px">
      <v-card>
        <v-toolbar color="green" dark dense flat>
          <v-toolbar-title class="text-h5">
            Confirmation Dialog
          </v-toolbar-title>
        </v-toolbar>
        <v-card-subtitle class="mt-3">This farmer record do not exist. <Br/>You have to created a new one!</v-card-subtitle>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green darken-1" text @click="close">
            Close
          </v-btn>
          <v-btn
            color="green darken-1"
            text
            outlined
            @click="openRegistration"
          >
            Continue
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialog2" max-width="600px">
      <v-card>
        <v-toolbar color="green" dark dense flat>
          <v-toolbar-title class="text-h5">
            Confirmation Dialog
          </v-toolbar-title>
        </v-toolbar>
        <v-card-subtitle class="mt-3">This farmer record exist. <Br/>You can either update it or save/update a value chain commodity</v-card-subtitle>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green darken-1" text @click="close">
            Close
          </v-btn>
          <v-btn
            color="green darken-1"
            text
            outlined
            @click="openUpdateRegistration"
          >
            Update Farmer
          </v-btn>
          <v-btn
            color="green darken-1"
            text
            outlined
            @click="openCommodity"
          >
            Commodity
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialog3" max-width="600px">
      <v-card>
        <v-toolbar color="green" dark dense flat>
          <v-toolbar-title class="text-h5">
            Confirmation Dialog
          </v-toolbar-title>
        </v-toolbar>
        <v-card-subtitle class="mt-3">
          This farmer record exist but cannot be edited <Br/>The farmer exist outside your coverage area</v-card-subtitle>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green darken-1" text @click="close">
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </Layout>
</template>

<script>
import Layout from '@/infdb_components/layouts/MainPortalLayout'
import httpServ from '../services/HTTPService'
import moment from 'moment'

export default {
  components: {
    Layout
  },
  data() {
    return {
      memberData:{},
      currentLOBDetails:{},
      prePostData:{},
      pageData:{},
      snackbar: false,
      snackbartext:'',
      hasActiveAccount:true,
      dialog1:'',
      dialog2:'',
      dialog3:'',

      state_arr:[],
      lga_arr:[],
      all_lga_arr:[],
      state:'',
      lga:'',
      identification_id:'',
      phone:'',
      valid:'',
      farmerData:{},

      namesRules: [
        (v) => !!v || 'Name is required',
        (v) =>
          (v && v.length >= 2) ||
        'Name must be greater than 2 characters'
      ],
      selectionsRules: [
        (v) => (v && v.length <= 11) || 'One or more comodities must be selected'
      ],
      phoneRules: [
        (v) => !!v || 'Phone Number is required',
        (v) => /^\d{11}$/.test(v) || 'Phone number be must be valid',
        (v) => (v && v.length <= 11) || 'Phone Number must be 11 figures'
      ],
      emailRules: [
        (v) => !!v || 'E-mail is required',
        (v) => /.+@.+\..+/.test(v) || 'E-mail must be valid'
      ]
    }
  },
  watch: {
    state: {
      handler(newvalue) {
        if (newvalue !== undefined) {
          this.lga_arr = this.all_lga_arr.filter(
            (lga) => lga.state_rf === this.state.id
          )
        }
      },
      immediate: true
    }
  },
  created() {
    this.userData = httpServ.getSessionJson('userData')
    this.work_state = this.userData.work_state
    this.user_id = this.userData.user_id
    this.getFormOption()
  },
  mounted() {},
  methods: {
    openRegistration() {
      httpServ.setSessionJson('farmerData',this.farmerData)
      //console.log(httpServ.getSessionJson('farmerData'))
      this.$router.push({ name: 'newRegistration' })
    },
    openUpdateRegistration() {
      httpServ.setSessionJson('farmerData',this.farmerData)
      //console.log(httpServ.getSessionJson('farmerData'))
      this.$router.push({ name: 'updateRegistration' })
    },
    openCommodity() {
      httpServ.setSessionJson('farmerData',this.farmerData)
      //console.log(httpServ.getSessionJson('farmerData'))
      this.$router.push({ name: 'commodity' })
    },
    close() {
      this.dialog1 = false
      this.dialog2 = false
      this.dialog3 = false
    },
    showDialog1: function() {
      this.dialog1 = true
    },
    showDialog2: function() {
      this.dialog2 = true
    },
    closeSnackbar: function () {
      snackbar = false
      this.$router.push({ name: 'farmersRegistration' })
    },
    getFormOption: function() {
      const formUrl = httpServ.getNAGSAPUrl('/enumeration/formOptions/verifier')
      const resValue = httpServ.makeRequest(formUrl, 'GET', { 'state_rf':this.userData.state_rf })

      resValue.then((result) => {
        //console.log('our select is ', result.data)
        //console.log('our states is ', result.data.states[0])
        if (result.status === 200) {
          this.state_arr = result.data.states
          this.all_lga_arr = result.data.lgas
        }
      })
    }, 
    getNewData: function() {
      const data = {
        state_rf:this.state.id,
        lga_rf:this.lga.id,
        phone:this.phone
      }

      return data
    }, 
    verify: function() { 
      const postData =  this.getNewData() 

      if (this.$refs.form.validate()) {
        const url = httpServ.getNAGSAPUrl('/enumeration/dataclerk/verify')
        const resp = httpServ.postFetch(url,postData)

        resp.then((result ) => {
          //console.log(result.data)
          if (result.status === 200) {//farmer exist
            this.farmerData = result.data
            this.dialog2 = true
          } else if (result.status === 201) {//new farmer
            this.farmerData = result.data
            this.dialog1 = true

          } else if (result.status === 403) {
            this.dialog3 = true
            //this.formBtnLoader = false
          } else if (result.status === 422) {
            
            if (result instanceof SyntaxError) {
              this.snackbartext = result.message
              this.snackbar = true
            } else {
              //console.log('There was an error', result) //Checker
              this.snackbartext = result.message
              this.snackbar = true
            }
            this.formBtnLoader = false
          } else {
            this.snackbartext = result.message 
            this.snackbar = true
          }
        })
      }
    }
  }
}
</script>

<style scoped>
</style>
