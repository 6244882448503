<template>
  <Layout is-demo>
    <div class="">
      <h1 class="text-h4">Commodity Farms</h1>
      <div class="caption green--text">{{ first_name }} from {{ village }}</div>
      <v-divider class="my-2"></v-divider>
    </div>
    <v-row dense>
      <v-col cols="12" class="">
        <v-card>
          <v-data-table
            :headers="headers"
            :items="tableData"
            sort-by="id"
            class="elevation-1"
            :loading="tableLoading"
            loading-text="Loading... Please wait"
            fixed-header
            height="350px"
            :no-data-text="tableNoDataText"
          >
            <template v-slot:item.commodity="{ item }">
              <span>{{ item.commodity | tableLen30 }}</span>
            </template>
            <template v-slot:top>
              <v-toolbar flat>
                <v-dialog v-model="dialog" max-width="600px">
                  <template v-slot:activator="{ on, attrs }" >
                    <v-btn
                      color="primary"
                      dark
                      class="ma-1 px-2"
                      v-bind="attrs"
                      outlined
                      v-on="on"
                    >
                      Add Commodity
                    </v-btn>
                  </template>
                  <v-card>
                    <v-toolbar color="green" dark dense flat>
                      <v-toolbar-title class="text-h5">
                        {{ formTitle }}
                      </v-toolbar-title>
                    </v-toolbar>
                    <v-form
                      ref="form"
                      v-model="valid"
                      lazy-validation
                    >
                      <small
                        class="pa-2 grey--text"
                      >*indicates required field</small><br/>
                      <v-container>
                        <v-row>
                          <v-col
                            cols="12"
                            sm="6"
                            md="6"
                            class="py-0"
                          >
                            <v-select
                              v-model="commodity_rf"
                              :disabled="commodity_disabled"
                              return-object
                              :items="commodity_arr"
                              item-text="title"
                              item-value="id"
                              label="Commodity*"
                              :rules="[v => !!v || 'Commodity is required']"
                              required
                            ></v-select>
                          </v-col>
                          <v-col
                            cols="12"
                            sm="6"
                            md="6"
                            class="py-0"
                          >
                            <v-text-field
                              v-model="farm_size_ha"
                              label="Farm Size(HA)*"
                              required
                              type="number"
                              :rules="numberRules"
                            ></v-text-field>
                          </v-col>
                          <v-col
                            cols="12"
                            sm="6"
                            md="6"
                            class="py-0"
                          >
                            <v-select
                              v-model="cluster_id"
                              return-object
                              :items="cluster_arr"
                              item-text="title"
                              item-value="id"
                              label="Cluster/Irrigation site*"
                              :rules="[v => !!v || 'Cluster is required']"
                              required
                            ></v-select>
                          </v-col>
                          <v-col
                            cols="12"
                            sm="6"
                            md="6"
                            class="py-0"
                          >
                            <v-text-field
                              v-model="sub_cluster"
                              label="Sub Cluster*"
                              required
                              :rules="clusterRules"
                            ></v-text-field>
                          </v-col>
                          <v-col
                            cols="12"
                            sm="6"
                            md="12"
                            class="py-0"
                          >
                            <v-text-field
                              v-model="farm_location"
                              label="Farm Location/Description*"
                              required
                              :rules="nameRules"
                            ></v-text-field>
                          </v-col>
                        </v-row>
                      </v-container>
                    </v-form>
                    <v-divider></v-divider>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn color="green darken-1" text @click="close">
                        Close
                      </v-btn>
                      <v-btn
                        color="green darken-1"
                        text
                        outlined
                        :loading="formBtnLoader"
                        @click="postData()"
                      >
                        Submit Data
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
                <v-dialog v-model="dialog2" max-width="600px">
                  <v-card>
                    <v-toolbar color="green" dark dense flat>
                      <v-toolbar-title class="text-h5">
                        Change Commodity Status
                      </v-toolbar-title>
                    </v-toolbar>
                    <v-form
                      ref="form2"
                      v-model="valid"
                      lazy-validation
                    >
                      <br/>
                      <v-container>
                        <v-row>
                          <v-col
                            cols="12"
                            class="py-0"
                          >
                            <span class="font-weight-black"> {{ selected_commodity }}</span>
                            will become the Primary Commodity for the farmer. Do you want to continue
                          </v-col>
                        </v-row>
                      </v-container>
                    </v-form>
                    <v-divider></v-divider>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn color="green darken-1" text @click="close2">
                        Close
                      </v-btn>
                      <v-btn
                        color="green darken-1"
                        text
                        outlined
                        :loading="formBtnLoader"
                        @click="postData2()"
                      >
                        Continue
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
              </v-toolbar>
            </template>
            <template v-slot:item.actions="{ item }" >
              <v-icon class="mr-2" @click="editItem(item)" >
                mdi-file-document-edit
              </v-icon> 
              <v-icon class="mr-2" @click="editPrimaryStatus(item)" >
                mdi-tree
              </v-icon>  
            </template>
            <template v-slot:no-data>
              <div class="caption">
                This table has no record! Try reloading it
              </div>
              <v-btn color="primary" outlined small @click="getTableData">
                Reload Data
              </v-btn>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
      
    <v-snackbar v-model="snackbar" right>
      {{ snackbartext }}
      <template v-slot:action="{ attrs }">
        <v-btn color="blue" text v-bind="attrs" @click="snackbar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </Layout>
</template>
  
<script>
import Layout from '@/infdb_components/layouts/MainPortalLayout'
import httpServ from '../services/HTTPService'
  
export default {
  components: {
    Layout
  },
  data() {
    return {
      snackbar: false,
      snackbartext:'',
      formTitle:'New Commodity Addition',
      valid:'',
      mode:'new',

      farmerData:{},
      village:'...',
      first_name:'...',
      record_count:0,
      selected_commodity:'',
      selected_commodity_rf:'',

      dialog: false,
      dialog2: false,
      tableLoading: true,
      singleSelect: true,
      tableNoDataText: 'This table has no record!',
      //record_count:0,

      commodity_rf:'',
      commodity_arr:{},
      cluster_id:'',
      cluster_arr:{},
      farm_size_ha:'',
      farm_location:'',
      sub_cluster:'',

      commodity_disabled:false,
      verifyBtnLoader: false,
      formDisabled: true,
      formBtnLoader: false,
      formDisabled2: true,
      formBtnLoader2: false,

      headers: [
        {
          text: 'Commodity',
          align: 'start',
          sortable: false,
          value: 'commodity'
        },
        { text: 'Farm Size (HA)', value: 'farm_size_ha' },
        { text: 'Location', value: 'farm_location' },
        { text: 'Cluster', value: 'cluster' },
        { text: 'Sub Cluster', value: 'sub_cluster' },
        { text: 'Commodity Status', value: 'commodity_status' },   
        { text: 'Modify', value: 'actions', sortable: false }
      ],
      tableData: [],
      editedIndex: -1,
      nameRules: [
        (v) => !!v || 'Location is required',
        (v) => (v && v.length >= 5) || 'Location must be greater than 8 characters'
      ],
      clusterRules: [
        (v) => !!v || 'Cluster is required',
        (v) => (v && v.length >= 1) || 'Cluster must be greater than 1 characters'
      ],
      dateRules: [
        (v) => !!v || 'Date is required',
        (v) => (v  &&  Date.parse(v.replaceAll(' ',''))) || 'Date must be valid'
      //(v) => (v  &&  v instanceof Date && !isNaN(v)) || 'Date must be valid'
      ],
      numberRules: [
        (v) => !!v || 'Farm size is required',
        (v) => (!isNaN(parseFloat(v))) || 'Farm size must be a number',
        (v) => (v && v > 0 ) || 'Famr size must greater than zero'
      ]
    }
  },
  created() {
    this.farmerData = httpServ.getSessionJson('farmerData')
    
    this.village = this.farmerData.village
    this.first_name = this.farmerData.first_name
    this.userData = httpServ.getSessionJson('userData')
    //console.log(this.userData)
    if (this.farmerData.farmer_id === '') {
      this.getFormOptionWithPhone()
    } else {
      this.getFormOptionWithFarmerId()
      this.getTableData()
    }
  },
  mounted() {},
  methods: {
    isEditOk: function(item) {
      if (item.approval_status < 3) {
        return true
      }  else {
        return false
      }
    },
    getFormOptionWithPhone: function() {
      const formUrl = httpServ.getNAGSAPUrl('/enumeration/formOptions/commodityByPhone')
      const resValue = httpServ.makeRequest(formUrl, 'GET', { phone:this.farmerData.phone })

      resValue.then((result) => {
        if (result.status === 200) {
          //console.log(result.data.farmer[0].farmer_id)
          this.commodity_arr = result.data.commodities
          this.farmerData.farmer_id = result.data.farmer[0].farmer_id
          httpServ.setSessionJson('farmerData',this.farmerData)
          this.getTableData()
        }
      })
    }, 
    getFormOptionWithFarmerId: function() {
      const formUrl = httpServ.getNAGSAPUrl('/enumeration/formOptions/commodityByFarmerId')
      const resValue = httpServ.makeRequest(formUrl, 'GET', { state_rf:this.userData.state_rf })

      resValue.then((result) => {
        if (result.status === 200) {
          this.commodity_arr = result.data.commodities
          this.cluster_arr = result.data.clusters
          //this.farmer_id = result.data.
        }
      })
    }, 
    getTableData: function() {
      const formUrl = httpServ.getNAGSAPUrl('/enumeration/dataclerk/farm/table')
      const resValue = httpServ.makeRequest(formUrl, 'GET', { 
        'farmer_id':this.farmerData.farmer_id
      })
      
      resValue.then((result) => {
        if (result.message === 'Request Ok!') {
          this.tableData = result.data
          this.record_count = 1
        }
        if (result.message === 'No record!') {
          this.record_count = 0
        }
        //console.log(this.tableData)
      })
      this.tableLoading = false
    },
    getNewData: function () {
      const data = {
        status: 'new',
        commodity_rf: this.commodity_rf.id,
        cluster_id: this.cluster_id.id,
        farm_location: this.farm_location,
        farm_size_ha: this.farm_size_ha,
        sub_cluster:this.sub_cluster,
        farmer_id:this.farmerData.farmer_id,
        phone:this.farmerData.phone,
        clerk_id:this.userData.clerk_id,
        record_count:this.record_count
      }

      return data
    },
    getUpdatedData: function () {
      let { cluster_id } = ''
      
      if (typeof this.cluster_id === 'object') {
        cluster_id = this.cluster_id.id
      } else {
        cluster_id = this.cluster_id
      }

      const data = {
        status: 'update',
        commodity_rf: this.commodity_rf,
        farm_location: this.farm_location,
        farm_size_ha: this.farm_size_ha,
        sub_cluster:this.sub_cluster,
        phone:this.farmerData.phone,
        farmer_id:this.farmerData.farmer_id,
        cluster_id: cluster_id,
        clerk_id:this.userData.clerk_id,
        record_count:this.record_count
      }

      return data
    },
    postData: function () {
      const postData =
        this.editedIndex > -1 ? this.getUpdatedData() : this.getNewData()
      
      //validate
      if (this.$refs.form.validate()) {
        this.formBtnLoader = true 
    
        const formUrl1 = httpServ.getNAGSAPUrl('/enumeration/dataclerk/farm/new')
        const formUrl2 = httpServ.getNAGSAPUrl('/enumeration/dataclerk/farm/update')
        const url = this.mode === 'new' ? formUrl1 : formUrl2
        const resp = httpServ.postFetch(url,postData)

        console.log(this.mode)
        /* const resp = httpServ.postFetchWithToken(
          url,
          postData,
          this.memberData.jwt
        )*/

        resp.then((result) => {
          this.formBtnLoader = false
          if (result.status === 200) {
            this.snackbartext = 'Entry was successful!'
            this.snackbar = true
            this.signinBtnText = 'Successful'
            this.close()
            this.getTableData()
          } else if (result.status === 422) {
            
            if (result instanceof SyntaxError) {
              // Unexpected token < in JSON
              //console.log('There was a SyntaxError', result)//checker
              this.snackbartext = result.message
              this.snackbar = true
            } else {
              //console.log('There was an error', result) //Checker
              this.snackbartext = result.message
              this.snackbar = true

            }
            this.formBtnLoader = false
          } else {
            this.snackbartext = result.message 
            this.snackbar = true
          }
        })
      } else {
        console.log('error')
      }
    },
    postData2: function () {
      const postData = {
        commodity_rf: this.selected_commodity_rf,
        phone: this.farmerData.phone
      }
      const formUrl = httpServ.getNAGSAPUrl('/enumeration/farm/commodity_status')
      const resp = httpServ.postFetch(formUrl,postData)

      resp.then((result) => {
        if (result.status === 200) {
          this.snackbartext = 'Entry was successful!'
          this.snackbar = true
          this.signinBtnText = 'Successful'
          this.close2()
          this.getTableData()
          this.formBtnLoader = false
        } else if (result.status === 422) {
            
          if (result instanceof SyntaxError) {
            // Unexpected token < in JSON
            //console.log('There was a SyntaxError', result)//checker
            this.snackbartext = result.message
            this.snackbar = true
          } else {
            //console.log('There was an error', result) //Checker
            this.snackbartext = result.message
            this.snackbar = true
          }
          this.formBtnLoader = false
        } else {
          this.snackbartext = result.message 
          this.snackbar = true
        }
      })
    },
    editItem(item) {
      this.editedIndex = this.tableData.indexOf(item)

      console.log(item)
      this.commodity_rf = item.commodity_rf
      this.farm_location = item.farm_location
      this.farm_size_ha =  item.farm_size_ha
      this.sub_cluster = item.sub_cluster
      this.cluster_id = item.cluster_id

      this.commodity_disabled = true
      this.dialog = true
      this.mode = 'update'
    },   
    editPrimaryStatus(item) {
      //console.log(item)
      this.selected_commodity = item.commodity
      this.selected_commodity_rf = item.commodity_rf
      this.dialog2 = true
    },
    close() {
      this.dialog = false
      this.$nextTick(() => {
      //this.editedItem = Object.assign({}, this.defaultItem)
        this.commodity_rf = ''
        this.farm_location = ''
        this.farm_size_ha =  ''
        this.sub_cluster = ''
        this.cluster_id = ''
        this.editedIndex = -1
        this.verifyBtnLoader = false
        this.formDisabled = true
        this.formBtnLoader = false
        this.commodity_disabled = false
        this.mode = 'new'
      })
    },
    close2() {
      this.selected_commodity = ''
      this.selected_commodity_rf = ''
      this.dialog2 = false
    }
  }
}
</script>
  
  <style scoped>
  </style>
