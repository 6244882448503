<template>
  <v-container class="py-6">
    <div class="text-center">
      <img
        src="/images/v-logo-small-dc.fw.png"
        aspect-ratio="16/9"
        alt=""
        height="41"
        class="mr-1 ourimage"
      />
      <br />
      &nbsp;
    </div>
    <v-card class="pa-3 mx-auto" max-width="500">
      <v-card-title class="justify-center">
        <v-divider class="my-1"></v-divider>
        <div class="mx-2">Sign In</div>
        <v-divider class="my-1"></v-divider>
      </v-card-title>
      <v-card-text>
        <v-text-field v-model="email" label="Email" outlined></v-text-field>
        <v-text-field
          v-model="password"
          label="Password"
          :type="showIcon ? 'text' : 'password'"
          outlined
          :append-icon="showIcon ? 'mdi-eye' : 'mdi-eye-off'"
          @click:append="showIcon = !showIcon"
        ></v-text-field>
        <v-btn
          block
          class="success"
          x-large
          :disabled="signinBtnStatus"
          @click="signIn"
        >
          {{ signinBtnText }}
        </v-btn>

        <div class="text-center mt-2 text-body-2">
          <!--<router-link to="#">Register</router-link>-->
          <!--<router-link to="#">Forgot your password?</router-link>-->
        </div>
      </v-card-text>
    </v-card>
    <v-snackbar v-model="snackbar" right>
      {{ snackbartext }}
      <template v-slot:action="{ attrs }">
        <v-btn color="blue" text v-bind="attrs" @click="snackbar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>

<script>
//import router from '../router'
import httpServ from '../services/HTTPService'

export default {
  data: () => ({
    email: 'e@e.com',
    password: 'dedc#4556',  
    //email: 'mee2@com.com',
    //password: 'jidc4472',
    showIcon: false,
    signinBtnStatus: false,
    signinBtnText: 'Sign In',
    snackbar: false,
    snackbartext: ''
  }),
  created() {
    //httpServ.clearSession()
    httpServ.removeSessionJson('userData')
    httpServ.removeSessionJson('memberData')
    httpServ.removeSessionJson('archiveData')
    httpServ.removeSessionJson('farmerData')
  },
  methods: {
    goToHome: function () {
      window.location.href = httpServ.getHomeUrl()
    },
    signIn2: function() {
      const base64 = require('base-64')

      const url = 'http://localhost:8000/b-aut/dataclerk/login'
      const username = 'auLogger1'
      const password = 'inFbd@T01789fe'

      const headers = new Headers()

      //headers.append('Content-Type', 'text/json');
      headers.append('Authorization', 'Basic ' + base64.encode(username + ':' + password))

      fetch(url, { method:'POST',
        headers: headers
        //credentials: 'user:passwd'
      })
        .then((response) => response.json())
        .then((json) => console.log(json))
    },//////
    signIn: function () {
      this.signinBtnStatus = true
      this.signinBtnText = 'Processing ...'

      const loginData = {
        email: this.email,
        permission: this.password
      }

      const url = httpServ.getAuthenUrl('/b-aut/dataclerk/login')
      const resp = httpServ.postFetch(url, loginData)
      //const resp = httpServ. postFetchWithBaSicToken(url,loginData)

      resp.then((result) => {
        if (result.status === 200) {
          this.snackbartext = 'Login Successful'
          this.snackbar = true
          this.signinBtnText = 'Successful'

          const userData = result.data

          httpServ.setSessionJson('userData', userData)
          const status = parseInt(userData.user_status_rf)
          
          if (status === 1) {
            setTimeout(async () => {
              await this.$router.push({ name: 'defaultChangePassword' })//.catch((err) => { console.log(err.message)})
            }, 1000)
          } else if (status === 2) {
            setTimeout(async () => {
              await this.$router.push({ name: 'dashboard' })//.catch((err) => { console.log(err.message)})
            }, 1000)
          } else {
            setTimeout(async () => {
              await this.$router.push({ name: '#' })//.catch((err) => { console.log(err.message)})
            }, 1000)
          }

          /*setTimeout(async () => {
            await this.$router.push({ name: 'dashboard' }) //.catch((err) => { console.log(err.message)})
          }, 1000)*/
        } else {
          this.snackbartext = result.message
          this.snackbar = true
          this.signinBtnStatus = false
          this.signinBtnText = 'Sign In'
        }
      })
    }
  }
}
</script>
<style scoped>
.ourimage:hover {
  cursor: pointer;
}
</style>
